 // 导航栏数据
 import { checkAuth } from './util';

 export function getMenuList() {
     return {
         subMenus: [
             {
                title: "运输计划",
                icon: "md-list",
                subName: "5",
                menuItems: [{
                    name: "5-1",
                    routerLink: "/schedule/makefence",
                    menuTitle: "绘制起终点",
                },{
                    name: "5-2",
                    routerLink: "/schedule/mapfence",
                    menuTitle: "起终点管理",
                },{
                    name: "5-3",
                    routerLink: "/schedule/device",
                    menuTitle: "设备线路绑定",
                },{
                    name: "5-4",
                    routerLink: "/schedule/devicefence",
                    menuTitle: "运输计划列表",
                },{
                    name: "5-5",
                    routerLink: "/schedule/shipping",
                    menuTitle: "生成运单数据",
                },{
                    name: "5-6",
                    routerLink: "/schedule/devicedriver",
                    menuTitle: "司机打卡记录",
                },{
                    name: "5-7",
                    routerLink: "/schedule/pushlist",
                    menuTitle: "消息推送记录",
                }]
             },
             {
                 title: "设备管理",
                 icon: "md-list",
                 subName: "1",
                 menuItems: [{
                     name: "1-1",
                     routerLink: "/manage/device-query",
                     menuTitle: "设备列表",
                 }, {
                     name: "1-2",
                     routerLink: "/manage/device-expire",
                     menuTitle: "设备过期列表",
                 }]
             },
             {
                 title: "客户管理",
                 icon: "md-list",
                 subName: "2",
                 menuItems: [{
                     check: checkAuth('user-query'),
                     name: "2-1",
                     routerLink: "/user/list",
                     menuTitle: "客户列表",
                 }, ]
             },
             {
                 title: "监控中心",
                 icon: "md-list",
                 subName: "3",
                 menuItems: [{
                     name: "3-1",
                     routerLink: "/monitor",
                     menuTitle: "监控中心",
                 }, ]
             },
             {
                 title: "企业配置",
                 icon: "md-list",
                 subName: "4",
                 menuItems: [{
                     name: "4-1",
                     routerLink: "/config",
                     menuTitle: "企业配置",
                 }, ]
             },
             // {
             //     title:"系统设置",
             //     icon:"md-settings",
             //     subName:"4",
             //     menuItems:[
             // {
             //     name:"4-1",
             //     routerLink:"/set/base-set",
             //     menuTitle:"基本设置",
             // },
             // {
             //     check:checkAuth('user-query'),
             //     name:"4-1",
             //     routerLink:"/user/list",
             //     menuTitle:"用户管理",
             // },
             // {
             //     check:checkAuth('user_group-query'),
             //     name:"4-2",
             //     routerLink:"/user/user_group",
             //     menuTitle:"权限管理",
             // },
             //     ]
             // },
         ]
     }
 }